import React from 'react';
import styles from './aboutUs.module.css';

const AboutUs = () => {
  return (
    <section id="nosotros">
      <div className={styles.main_background}>
        <div className={styles.main_background_container}>
          <h1>Residencia de larga estadía para adultos mayores</h1>
          <p>
            Residencia Plaza es una institución diseñada para asistir adultos mayores, con una
            privilegiada ubicación en la ciudad de Rosario. Dispone de personal especializado en
            tratamientos psicogeriatricos
          </p>
        </div>
      </div>
      <div className={styles.welcomeSection}>
        <div className={styles.welcomeSuperContainer}>
          <div className={styles.welcomeContainer}>
            <div className={styles.welcomeSubContainer}>
              <h2>Bienvenidos</h2>
              <p>
                Fundada en el año 1982, la Residencia Plaza se originó como una iniciativa familiar.
                Actualmente dirigida por una gerencia con más de 40 años de experiencia en la
                gestión de Centros para personas mayores.
              </p>
              <p>
                Ofrecemos un cuidado completo y constante a nuestros residentes, con un enfoque
                gerontológico e interdisciplinario, proporcionando un ambiente acogegedor y familiar
                para que se sientan como en casa.
              </p>
              <div className={styles.linescontainer}>
                <div className={styles.line}></div>
                <img
                  src="./images/messageicon.svg"
                  alt="Logo de texto"
                  className={styles.textimg}
                />
                <div className={styles.line}></div>
              </div>
            </div>
          </div>
          <img
            src="./images/welcome.jpg"
            className={styles.welcomeimg}
            alt="Entrada residencia plaza"
          />
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
