import React from 'react';
import styles from './contact.module.css';

const Contact = () => {
  return (
    <>
      <div id="contacto" className={styles.section5}></div>
      <section className={styles.section}>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3348.2528166123193!2d-60.650263!3d-32.944334!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95b7ab3fbd6155a7%3A0x3f733403945d9e63!2sGeriatrico%20Plaza!5e0!3m2!1ses!2sar!4v1703621432779!5m2!1ses!2sar"
          allowfullscreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          className={styles.map}
        ></iframe>
        <div className={styles.infoSuperContainer}>
          <h2 className={styles.h2}>|UBICACIÓN Y CONTACTO|</h2>
          <h3 className={styles.h3}>¿Queres contactarte con nuestra residencia?</h3>
          <div className={styles.info}>
            <div className={styles.infoContainer}>
              <div className={styles.img}>
                <img src="./images/address.png" alt="casa" />
              </div>
              <p>Cordoba - 1971 Rosario - Santa Fe.</p>
            </div>
            <div className={styles.infoContainer}>
              <div className={styles.img}>
                <img src="./images/phone.png" alt="telefono" />
              </div>
              <p>341-424-2932</p>
            </div>
            <div className={styles.infoContainer}>
              <div className={styles.img}>
                <img src="./images/email.png" alt="email" />
              </div>
              <p>institutogeriatricoplaza@hotmail.com</p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
