import React from 'react';
import styles from './services.module.css';

const Services = () => {
  const services = [
    'Servicio Medico',
    'Podología',
    'Atención Psicogeriatrica',
    'Musicoterapia',
    'Enfermería',
    'Servicio de limpieza',
    'Nutrición',
    'Lavandería',
    'Kinesiología',
    'Acompañamientos',
    'Trabajo Social',
    'Salidas guiadas',
    'Taller de Psicología',
    'Wi Fi',
    'Terapia Ocupacional'
  ];

  return (
    <>
      <div id="servicios" className={styles.section4}></div>
      <section className={styles.section}>
        <h2 className={styles.h2}>|SERVICIOS|</h2>
        <div>
          <h3 className={styles.h3}>Servicios geriatricos en nuestra residencia</h3>
          <p className={styles.p}>
            Contamos con un equipo interdisciplinario de profesionales especializados, con larga
            trayectoria en el cuidado y tratamiento del adulto mayor. Ofrecemos atención
            personalizada que abarca todos los aspectos de la salud y el bienestar, proporcionando
            tranquilidad tanto para ellos como para sus familias.
          </p>
        </div>
        <div className={styles.services}>
          <ul className={styles.ul}>
            {services.map((service) => (
              <li key={service} className={styles.li}>
                <div>
                  <img src="./images/check.png" alt="check" />
                  <span className={styles.span}>{service}</span>
                </div>
                <div className={styles.line}></div>
              </li>
            ))}
          </ul>
          <img
            src="./images/services_hands.jpg"
            alt="servicios disponibles"
            className={styles.handsImg}
          />
        </div>
      </section>
    </>
  );
};

export default Services;
