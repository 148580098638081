import React, { useState } from 'react';
import styles from './reel.module.css';

const Reel = ({ imgs }) => {
  const [cont, setCont] = useState(0);

  const contAddition = () => {
    if (imgs.length > cont + 1) {
      setCont(cont + 1);
    } else {
      setCont(0);
    }
  };

  const contSubstraction = () => {
    if (cont - 1 >= 0) {
      setCont(cont - 1);
    } else {
      setCont(imgs.length - 1);
    }
  };

  return (
    <div className={styles.container}>
      <div className={`${styles.arrow} ${styles.arrowLeft}`} onClick={contSubstraction}>
        <img src="./images/arrow_left.png" alt="left arrow" className={styles.arrowImg} />
      </div>
      {imgs.map((img, index) => {
        return (
          <img
            key={index}
            src={img.src}
            alt={img.alt}
            className={`${styles.img} ${cont !== index ? styles.none : styles.active}`}
          />
        );
      })}
      <div className={`${styles.arrow} ${styles.arrowRight}`} onClick={contAddition}>
        <img src="./images/arrow_right.png" alt="right arrow" className={styles.arrowImg} />
      </div>
    </div>
  );
};

export default Reel;
