import React from 'react';
import styles from './bedrooms.module.css';
import Reel from '../Shared/reel';

const Bedrooms = () => {
  const imgs = [
    { src: './images/bedrooms1.jpg', alt: 'muestra de habitación cama simple' },
    { src: './images/bedrooms2.jpg', alt: 'muestra de habitación cama simple 2' },
    { src: './images/bedrooms3.jpg', alt: 'muestra de habitación camas simple' },
    { src: './images/bano.jpg', alt: 'baño de la habitación' }
  ];
  return (
    <>
      <div id="habitaciones" className={styles.section3}></div>
      <section className={styles.section}>
        <h2 className={styles.h2}>|HABITACIONES|</h2>
        <div className={styles.infoContainer}>
          <h3 className={styles.h3}>Nuestras Habitaciones</h3>
          <div className={styles.pContainer}>
            <p>
              Nuestro centro, dispone de capacidad para atender a 39 residentes, tanto en
              habitaciones dobles como triples, todas ellas están equipadas para proporcionar una
              cómoda estadía, incluyendo servicio de limpieza y lavandería. Los ambientes estan bien
              iluminados, cuentan con ventilación, baños adaptados y libre acceso a WIFI para todos
              los usuarios.
            </p>
          </div>
          <div className={styles.img}>
            <Reel imgs={imgs} />
          </div>
        </div>
        <div className={styles.carrusel}></div>
      </section>
    </>
  );
};

export default Bedrooms;
