import React from 'react';
import styles from './footer.module.css';

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.box}>
        <div className={styles.residencia}>Residencia</div>
        <div className={styles.line}></div>
        <p className={styles.plaza}>PLAZA</p>
      </div>
      <p className={styles.p}>© 2023 - Residencia Plaza. Todos los derechos reservados.</p>
    </footer>
  );
};

export default Footer;
