import React from 'react';
import styles from './installations.module.css';
import Reel from '../Shared/reel';

const Installations = () => {
  const imgs = [
    { src: './images/patio.jpg', alt: 'patio del geriatrico' },
    { src: './images/pasillo.jpg', alt: 'pasillo del geriatrico' },
    { src: './images/cocina.jpg', alt: 'cocina del geriatrico' }
  ];

  return (
    <>
      <div id="instalaciones" className={styles.section2}></div>
      <section className={styles.section}>
        <h2 className={styles.h2}>|INSTALACIONES|</h2>
        <h3 className={styles.h3}>Nuestras Instalaciones</h3>
        <div className={styles.installationsContainer}>
          <div className={styles.installationsText}>
            <p>
              Nuestra residencia se distribuye en dos plantas y una terraza habilitada. Para
              garantizar la accesibilidad, contamos con un ascensor camillero que conecta los tres
              pisos.
            </p>
            <p>
              Toda la institución está equipada con calefacción central, aire acondicionado en áreas
              comunes, WiFi y televisión por cable para el entretenimiento. Contamos con un office
              de enfermería en cada piso, consultorio médico, habitaciones y baños adaptados para
              las necesidades de nuestros residentes, cocina propia totalmente equipada y lavadero
              industrial habilitado.
            </p>
          </div>
          <div className={styles.reelContainer}>
            <div className={styles.installationsImg}>
              <Reel imgs={imgs} />
            </div>
          </div>
        </div>
        <img
          src="./images/instalations_hands.jpg"
          alt="cuidados personalizados"
          className={styles.handsimg}
        />
      </section>
    </>
  );
};

export default Installations;
