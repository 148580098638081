import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Header from './Components/Header';
import AboutUs from './Components/AboutUs';
import Installations from './Components/Installations';
import Bedrooms from './Components/Bedrooms';
import Services from './Components/Services';
import Contact from './Components/Contact';
import Footer from './Components/Footer';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Header />
    <AboutUs />
    <Installations />
    <Bedrooms />
    <Services />
    <Contact />
    <Footer />
  </React.StrictMode>
);

reportWebVitals();
