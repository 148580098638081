import React, { useState, useEffect } from 'react';
import styles from './header.module.css';

const Header = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [navVisible, setNavVisible] = useState(true);

  const toggleNavVisible = () => {
    setNavVisible(!navVisible);
  };

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToSection = (sectionId, type) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
    if (type !== 'logo') {
      toggleNavVisible();
    }
  };

  return (
    <header
      className={`${styles.header} ${
        scrollPosition === 0 ? styles.headerInvisible : styles.headerVisible
      }`}
    >
      <div
        className={`${styles.box} ${
          scrollPosition === 0 ? styles.boxInvisible : styles.boxVisible
        }`}
        onClick={() => scrollToSection('nosotros', 'logo')}
      >
        <div className={styles.residencia}>Residencia</div>
        <div
          className={`${styles.line} ${
            scrollPosition === 0 ? styles.lineInvisible : styles.lineVisible
          }`}
        ></div>
        <p className={styles.plaza}>PLAZA</p>
      </div>
      <nav className={`${styles.nav} ${navVisible ? styles.none : styles.flex}`}>
        <a className={styles.a} onClick={() => scrollToSection('nosotros')}>
          Nosotros
        </a>
        <a className={styles.a} onClick={() => scrollToSection('instalaciones')}>
          Instalaciones
        </a>
        <a className={styles.a} onClick={() => scrollToSection('habitaciones')}>
          Habitaciones
        </a>
        <a className={styles.a} onClick={() => scrollToSection('servicios')}>
          Servicios
        </a>
        <a className={styles.a} onClick={() => scrollToSection('contacto')}>
          Ubicación
        </a>
        <div className={styles.phone}>
          {scrollPosition === 0 ? (
            <img src="./images/nav_white_phone.png" alt="telefono" className={styles.phoneImg} />
          ) : (
            <img src="./images/nav_black_phone.png" alt="telefono" className={styles.phoneImg} />
          )}
          <p className={styles.phonetxt}>Tel: 3414242932</p>
        </div>
        <div
          className={`${styles.contact} ${
            scrollPosition === 0 ? styles.contactInvisible : styles.contactVisible
          } ${styles.a}`}
          onClick={() => scrollToSection('contacto')}
        >
          <img src="./images/nav_email.png" alt="email" className={styles.emailImg} />
          <a>Contacto</a>
        </div>
        <div className={styles.spaceDiv}></div>
      </nav>
      <img
        src="./images/hamburger_menu.png"
        alt="menu"
        className={`${styles.hamburger} ${
          scrollPosition === 0 ? styles.hamburgerInvisible : styles.hamburgerVisible
        } ${!navVisible ? styles.navHamburger : null}`}
        onClick={toggleNavVisible}
      />
    </header>
  );
};

export default Header;
